/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';

import Nav from "components/Nav"
import './layout.scss'
// import StaticBackgroundImage from "components/StaticBackgroundImage";


const Layout = ({ children, style, ...props }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery{
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Nav siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          ...style,
        }}
      >
        {children}
      </div>
      <footer style={{textAlign: 'center'}}><a key="4lw" target="_blank" title="Créé par 4LW" rel="noreferrer" href="https://4lw.fr">4LW</a></footer>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
