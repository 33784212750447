// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-templates-agence-jsx": () => import("./../../../src/templates/agence.jsx" /* webpackChunkName: "component---src-templates-agence-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-listprojets-jsx": () => import("./../../../src/templates/listprojets.jsx" /* webpackChunkName: "component---src-templates-listprojets-jsx" */),
  "component---src-templates-projets-jsx": () => import("./../../../src/templates/projets.jsx" /* webpackChunkName: "component---src-templates-projets-jsx" */)
}

