import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Navbar, Nav, Container } from "react-bootstrap";
import Img from 'gatsby-image'
// import Logo from "assets/logo.svg"
import "./Nav.scss"

const MyNav = ({siteTitle}) => {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      entries: allMdx(sort: {fields: [frontmatter___weight], order: ASC}, filter: {frontmatter: {menus: {eq: "main"}}}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);
  const entries = data.entries.edges;
  return (
    <Navbar expand="lg" className="navbar justify-content-between">
      <Container fluid className="container">
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mr-auto justify-content-between">
            {entries.map((entry, i) => (
              <Nav.Link activeClassName="active" key={i} as={Link} to={entry.node.fields.slug}>
                {entry.node.frontmatter.title}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand as={Link} to="/">
          {/* <Logo /> */}
          <Img style={{
                    width:"150px"}} fluid={data.placeholderImage.childImageSharp.fluid} alt="Pierre Abéla"/>
        </Navbar.Brand>
        </Container>
    </Navbar>
  );
}

export default MyNav;